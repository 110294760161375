<template>
    <div :class="['wrapper', classes]">

        <!-- Navbar -->
        <navigation-header @logout="logout()"></navigation-header>

        <!-- Main Sidebar Container -->
        <!-- Left side column. contains the logo and sidebar -->
        <sidebar @logout="logout"></sidebar>

        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
            <global-errors></global-errors>
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0 text-dark">
                                {{ componentName }}
                                <small>{{ componentDescription }}</small>
                            </h1>
                        </div>
                        <div class="col-sm-6">
                            <!--<ol class="breadcrumb float-sm-right">-->
                                <!--<li class="breadcrumb-item">-->
                                    <!--<router-link to="/"> {{ t('menu.dashboard') }} </router-link>-->
                                <!--</li>-->
                            <!--</ol>-->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Main content -->
            <div class="content">
                <!-- component matched by the route will render here -->
                <!-- see https://next.router.vuejs.org/guide/#router-view -->
                <router-view></router-view>
            </div>
        </div>
        <!-- /.content-wrapper -->

        <!-- Main Footer -->
        <footer class="main-footer">
            <div class="float-right d-none d-sm-block">
                <b>Version</b> 3.7.30
            </div>
            <strong> &copy;&nbsp; {{ currentYear }} Universität Greifswald - Universitätsrechenzentrum</strong>
        </footer>
    </div>
    <!-- ./wrapper -->
</template>

<script>
    import umsMixin from '../mixins/UMS';
    import componentResizeMixin from '../mixins/ComponentResize';
    import config from '../config';
    import GlobalErrors from './GlobalErrors.vue';
    import NavigationHeader from './NavigationHeader.vue';
    import Sidebar from './Sidebar.vue';
    let interval, timeout;
    export default {
        mounted(){
            this.$store.dispatch('account/loadAccount').then(() => {
                if (!this.user.active) {
                    return this.$router.push({name: 'account.password'});
                }
            });
            timeout = setTimeout(this.updateStatus, 500);
            interval = setInterval(this.updateStatus, this.config('status_intervall', 300) * 1000);
        },
        destroyed(){
            clearInterval(interval);
            clearTimeout(timeout);
        },
        mixins    : [umsMixin, componentResizeMixin],
        name      : 'Dash',
        components: {
            GlobalErrors,
            NavigationHeader,
            Sidebar,
        },
        data      : function () {
            return {
                // section: 'Dash',
                classes : {
                    fixed_layout: config.fixedLayout,
                    hide_logo   : config.hideLogoOnMobile
                },
                error   : '',
                userInfo: {messages: [], notifications: [], tasks: []},
            }
        },
        computed  : {
            activeComponent(){
                let matched = this.$route.matched;
                if (!matched.length) return {};
                let component = matched[matched.length - 1].components.default;
                let meta = component.component;
                if (!meta) {
                    meta = {};
                }
                return meta;
            },
            componentName(){
                return this.tOrTake(this.activeComponent.name);
            },
            componentDescription(){
                return this.tOrTake(this.activeComponent.description);
            },
            currentYear() {
                return new Date().getFullYear();
            }
        },
        methods   : {
            logout(){
                this.$store.dispatch('account/logout')
                    .then(response => {
                        this.$router.push('/login');
                    }).catch(error => {
                });
            },
            updateStatus(){
                this.$store.dispatch('updateStatus');
            }
        }
    }
</script>